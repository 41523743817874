<template>
<FormWrapper :is-loading="isLoading">
    <PageTitle title="Add Shift Override" slot="title" link="/helpContent/Add Shift Override" />
    <ValidationObserver ref="validator">
            <FormRow>
                <div class="col-sm-3">
                    <FormSelect label="Action" :items="actionList" item-name="name" item-value="id" v-model="form.selectedAction" rules="required" />
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Staff Name" :items="staffList" item-name="Name" item-value="id" v-model="form.selectedStaff" rules="required" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-2">
                    <inputDate label="Date" v-model="form.Date" rules="required" />
                </div>
                <div class="col-sm-2">
                    <InputTime label="Start Time" v-model="form.StartTime" rules="required" />
                </div>
                <div class="col-sm-2">
                    <InputTime label="End Time" v-model="form.EndTime" rules="required" />
                </div>
            </FormRow>
    
    </ValidationObserver>
    <br />
    <FormRow>
        <div class="col-sm-4">
            <ButtonGroup>
                <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                <FormButton type="success" @click="sendData()">Save</FormButton> 
            </ButtonGroup>
        </div>
    </FormRow>
    <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
    </Popup>
    <Popup title="Information" type="info" :value="infoMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ infoMessage.message }}
    </Popup>
</FormWrapper>
</template>
<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {getAllStaffByHRorManager, shiftOverride} from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import {ValidationObserver} from "vee-validate"

export default {
    name: "add",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
        InputDate,
        FormWrapper,
        PageTitle,
        ValidationObserver,
    },
    data() {
        return {
            showMessage: {
                isVisible: false,
            },
            infoMessage: {
                isVisible: false,
            },
            actionList: [
                       { name: "Add To Shift", id: 1 },
                       { name: "Change Timings", id: 2 },
                       { name: "Remove from Shift", id: 3 },
                        { name: "Swap The Shift", id: 4 }
                        ],
            staffList: [],
            form: {
                Date: "",
                StartTime: "",
                EndTime: "",
                selectedStaff:null,
                selectedAction: null,
                IsNotRequired:'',
            },
        };
    },
    computed: {
        userData() {
            return this.$store.state.user.user;
        },
    },
    watch: {},

    created() {
      this.getAllList()
    },
    methods: {
      async  getAllList(){
            await getAllStaffByHRorManager().then((res)=>{
            this.staffList=res.data
            this.form.selectedStaff=this.userData.user_id;
            })
          },
          sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            StaffId : this.form.selectedStaff,
                            Date : this.form.Date ,
                            StartTime: this.form.StartTime,
                            EndTime : this.form.EndTime ,
                            IsNotRequired:this.form.IsNotRequired,
                        };
                        shiftOverride(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.message;
                        })
                    }
                })
            },
    },
};
</script>

<style lang="scss">
.popupRow {
    padding: 5px 0 15px;
}

.pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
}

.paginationText {
    margin: 0 20px;
}

.paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
        box-shadow 200ms ease;
}

.paginationButton:hover {
    background: #57a3f3;
    color: #fff;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
}

.filter {
    margin: -10px 0 10px;
}

.filterItem {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.filterItemCheckbox {
    flex-basis: auto;
    align-self: flex-start;
    margin-top: 13px;
}
</style>
